import React from "react";
import styled from "styled-components";
import tw from 'twin.macro'
import { Element, Link } from 'react-scroll'
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Charity from "../../images/charity.png"
import { BsArrowDownCircle } from "react-icons/bs";

const CharitySectionContainter = tw.div`
    w-full
    height[85vh]
    flex
    flex-col
    relative
    items-center
`;

const Title = tw.h1`
    text-4xl
    xl:text-5xl
    2xl:text-7xl
    2xl:mt-6
    2xl:mb-6
    text-gray-800
    font-bold
    text-center
`;

const SectionInfo = tw.p`
    text-base
    lg:text-lg
    2xl:text-3xl
    text-gray-700
    text-center
    mt-3
    max-w-lg
    xl:max-w-3xl
    pl-4
    pr-4
    lg:pl-2
    lg:pr-2
`;

const CharitySectionWrapper = tw.div`
    w-2/3
    h-full
    flex
    flex-col
    justify-center
    items-center
    xl:mt-6
    2xl:mt-10
    max-w-sm
    xl:max-w-2xl
    2xl:max-w-4xl
`;

export function CharitySection() {
    return (
        <CharitySectionContainter>
            
            <CharitySectionWrapper>
            <Title>Spread Love and Kindness</Title>
            <img src={Charity} />
            <SectionInfo>
            Kumo x World has worked with the above charities, giving back through art auctions and project collaborations</SectionInfo>
            </CharitySectionWrapper>
            {/* <ViewMoreButton>
                <Link to="Footer" smooth={"easeInOutQuad"} duration={1500}>
                    <BsArrowDownCircle />
                </Link>
            </ViewMoreButton> */}
        </CharitySectionContainter>
    );
}