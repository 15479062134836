import styled from "styled-components";
import tw from "twin.macro";
import { NavBar } from "../../components/NavBar";
import { FooterIconSection } from "../../components/FooterIcons";
import { ResidentsSection } from "../../components/Residents";
import { ResidentsBanner } from "../../components/ResidentBanner";
import { ExpeditionResidentsSection } from "../../components/ExpeditionResidents";
import Sakura from "../../images/gallery/sakura2.png";
import Omuk from "../../images/gallery/omuk2.png";

const StorytimeContainer = styled.div`
    ${tw`
        w-full
        flex
        flex-col
        h-full
        relative
        align-items[center]
    `}
`;

const NavBG = tw.div`
    w-full
    background-color[#87bbb8]
`

const LandingSection = styled.div`
  ${tw`
        w-full
        h-screen
        flex
        flex-col
    `};
  background-image: url(${Sakura});
  background-size: cover;
  background-position: bottom 10% left;
  background-blend-mode: overlay;
`;

const ImageBanner = styled.div`
    ${tw`
        grid 
        max-w-full
        lg:grid 
    `}
`

export function Storytime() {
    return (
        <StorytimeContainer>
            <NavBG><NavBar /></NavBG>
            <img className="w-full max-w-full" src={Sakura}></img>
            <div className="flex flex-col items-center bg-gradient-to-b from-sakura-pink-500 to-turquoise-300 pt-10 pb-10 w-full max-w-full">
            <ResidentsSection></ResidentsSection>
            </div>
            <img className="w-full max-w-full" src={Omuk}></img>
            <div className="flex flex-col items-center bg-gradient-to-b from-night-blue-300 to-turquoise-100 pt-10 pb-10  w-full max-w-full">
            <ExpeditionResidentsSection></ExpeditionResidentsSection>
            </div>
            {/* <ResidentsBanner></ResidentsBanner> */}
            <FooterIconSection></FooterIconSection>
        </StorytimeContainer>)
};
