import React from 'react';
import tw from 'twin.macro';
import { TopSection } from '../../containers/TopSection';
import { WhoAreWeSection } from '../../containers/WhoAreWe';
import { CharitySection } from '../../containers/Charity';
import { WorkWithUsSection } from '../../containers/WorkWithUs';
import { FooterSection } from '../../containers/Footer';
import { GeneralInfoSection } from '../../containers/GeneralInfo';


const HomeContainer = tw.div`
  flex
  flex-col
  w-full
  h-full
  overflow-hidden
  align-items[center]
`;

export function Home(){
  return <HomeContainer>
    <TopSection></TopSection>
    <GeneralInfoSection></GeneralInfoSection>
    <WorkWithUsSection></WorkWithUsSection>
    <CharitySection></CharitySection>
    <FooterSection></FooterSection>
  </HomeContainer>
}
