import React from 'react';
import './App.css';
import tw from 'twin.macro';
import { TopSection } from './containers/TopSection';
import './fonts/Kumo-Regular.ttf';
import {Home} from './page/Home';
import { ContactUs } from './page/ContactUs';
import { Route, Routes } from 'react-router-dom';
import { AboutUs } from './page/About';
import { Storytime } from './page/Storytime';
import MetaConnect from './page/Verify';


const AppContainer = tw.div`
  flex
  flex-col
  w-full
  h-full
  // overflow-hidden
  align-items[center]
`;

function App() {
  return (
  <AppContainer>
    <Routes>
      <Route path='/' element={<Home/>}></Route>
      <Route path='/about' element={<AboutUs/>}></Route>
      <Route path='/contact' element={<ContactUs/>}></Route>
      <Route path='/storytime' element={<Storytime/>}></Route>
      <Route path='/verifyvip' element={<MetaConnect />}></Route>
    </Routes>
  </AppContainer>)
}

export default App;
