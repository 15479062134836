import { Button, Paper, Stack, Typography } from "@mui/material";
import axios from "axios";
import { ethers } from "ethers";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import tw from 'twin.macro'
import { CustomButton } from "../../tailwind-css";
import BackgroundImage from "../../images/bg3.png";
import { FooterSection } from "../../containers/Footer";
import { FooterBackground } from "../../components/FooterIcons";

const LandingSection = styled.div`
  ${tw`
        w-full
        h-screen
        flex
        flex-col
        position[relative]
    `};
  background-image: url(${BackgroundImage});
  background-size: cover;
  background-position: bottom 10% left;
  background-blend-mode: overlay;
`;

const VerifySection = tw.div`
    flex
    flex-col
    justify-center
    items-center
    h-full
    align-items[center]
    color[white]
`
const VerifyBannerBG = tw.div`
    absolute
    opacity-80
    w-full
    // padding-bottom[6rem]
    padding[100%]
    
    background-color[black]
`


const MetaConnect = () => {
    const [errorMessage, setErrorMessage] = useState("");
    const [account, setAccount] = useState("");
    const [balance, setBalance] = useState("");
    const [loading, setLoading] = useState(false);
    const [responseData, setResponseData] = useState({ data: "" })

    const verifyUser = (walletAddress: any) => {
        setLoading(true);
        setResponseData({ data: "Please wait while we verify, this may take a few minutes..." })
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString)
        const kumoCandy = urlParams.get('kumocandy')
        console.log("kumoCandy: " + kumoCandy)
        let verifyurl = 'https://0xblank.com:3000/' + kumoCandy + '/' + walletAddress

        axios.get(verifyurl)
            .then(response => {
                setLoading(false);
                const data = response.data;
                console.log(`response data: `, data.message);
                setResponseData({ data: data.message })
            })
            .catch(error => {
                setLoading(false);
                console.error(error.data.message)
                setResponseData({ data: error.data.message })
            });
    }

    useEffect(() => {
        if (window.ethereum) {
            window.ethereum.on("accountsChanged", accountsChanged);
            window.ethereum.on("chainChanged", chainChanged);
        }
    }, []);

    const connectHandler = async () => {
        if (window.ethereum) {
            try {
                const res = await window.ethereum.request({
                    method: "eth_requestAccounts",
                });
                await accountsChanged(res[0]);
            } catch (err) {
                console.error(err);
                setErrorMessage("There was a problem connecting to MetaMask");
            }
        } else {
            setErrorMessage("Install MetaMask");
        }
    };

    const accountsChanged = async (newAccount: any) => {
        console.log("accountschanged");
        console.log(newAccount)
        setAccount(newAccount);
        try {
            const balance = await window.ethereum.request({
                method: "eth_getBalance",
                params: [newAccount.toString(), "latest"],
            });
            setBalance(ethers.utils.formatEther(balance));
        } catch (err) {
            console.error(err);
            setErrorMessage("There was a problem connecting to MetaMask");
        }
    };

    const chainChanged = () => {
        setErrorMessage("");
        setAccount("");
        setBalance("");
    };

    const Connected = (props: any) => {
        if (!props.account) {
            return (<div>
                <CustomButton onClick={connectHandler}>Connect Account</CustomButton>
                 {
                   errorMessage ? (
                        <Typography variant="body1" color="red">
                          Error: {errorMessage}
                       </Typography>
                     ) : null
                 }</div>
            )
        }
        else {
            return (
                <div className="mb-6">
                    <CustomButton onClick={() => verifyUser({ account })} className="link-block-bank w-inline-block">
                        <div className="text-block-2">Verify VIP Role</div>
                    </CustomButton>
                </div>
            )
        }

    }

    return (
        <LandingSection>
            <VerifyBannerBG></VerifyBannerBG>
            <VerifySection>
            <h1 className="absolute text-3xl pb-32 mr-5 ml-5 text-center">Please connect your wallet to verify for VIP role in our discord. </h1>
                <Connected account={account}></Connected>
                <p className="absolute pt-20">{responseData.data}</p>
            </VerifySection>
        </LandingSection>
    );
};

export default MetaConnect;