import React from "react";
import styled from "styled-components";
import tw from 'twin.macro'
import { Element } from 'react-scroll'
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import WhoAreWeImg from "../../images/whoarewe.png"
import Run from "../../images/run.gif"
import { BsArrowDownCircle } from "react-icons/bs";
import { Link } from "react-scroll";
import { Link as RouterLink } from "react-router-dom"
import BackgroundImage from "../../images/bg.png";
import { CustomButton } from "../../tailwind-css";

const WorkWithUsSectionContainter = tw.div`
    w-full
    height[70vh]
    flex
    flex-col
    relative
`;

const LandingSection = styled.div`
  ${tw`
        w-full
        h-screen
        flex
        flex-col
    `};
  background-image: url(${BackgroundImage});
  background-size: cover;
  background-position: bottom 10% left;
  background-blend-mode: overlay;
`;


const InfoSection = styled.div`
  ${tw`
        left[2.5rem]
        absolute
        bottom[10%]
        // lg:bottom[135px]
        lg:left[4rem]
        lg:right-auto
        2xl:left-48
        // 2xl:bottom[240px]
        2xl:right-auto
    `};
`;

const FloatingText = styled.h1`
  ${tw`
        m-0
        font-black
        text-white
        font-size[30px]
        line-height[35px]
        lg:font-size[55px]
        lg:line-height[50px]
        2xl:font-size[100px]
        2xl:line-height[100px]
        font-family["Archivo Narrow"]
        flex
        items-center
    `};
`;

const DescriptionText = styled.p`
  ${tw`
        mt-2
        lg:mt-3
        xl:mt-3
        mb-2
        lg:mb-3
        xl:mb-4
        text-base
        lg:font-size[1.3rem]
        2xl:font-size[45px]
        text-white
        max-w-xs
        lg:max-w-lg
        2xl:max-w-xl
    `};
`;

export function WorkWithUsSection() {
    return <WorkWithUsSectionContainter>
        <LandingSection>
            <InfoSection>
                <FloatingText>PARTNER</FloatingText>
                <FloatingText>
                    WITH US</FloatingText>
                <DescriptionText>
                    Together. Anything is POSSIBLE
                </DescriptionText>
                <CustomButton>
                    <RouterLink to="/contact">Get in Touch</RouterLink>
                </CustomButton>
            </InfoSection>
        </LandingSection>
    </WorkWithUsSectionContainter>
};