import styled from "styled-components";
import tw from "twin.macro";
import {BrowserRouter, Link, Outlet, Route, Navigate} from "react-router-dom"


const LogoContainer = styled.div`
    ${tw`
        font-family[kumoregular]
        font-black
        text-4xl
        lg:text-6xl
        text-white
        whitespace-nowrap
        position[static]
    `}
`;

export function Logo() {
    return <LogoContainer>
        <Link to="/">Kumo x World</Link>
    </LogoContainer>
}

// export function Logo() {
//     return <div>
//          <Navigate to='/' replace={true} />
//   </div>};
