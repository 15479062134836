import React from "react";
import styled from "styled-components";
import tw from 'twin.macro'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { SectionContainter, Title, TwoColumnWrapper } from "../../containers/WhoAreWe";
import OGApe from "../../images/gallery/OGApe.webp";
import OGHarajuku from "../../images/gallery/OGHarajuku.webp";
import OGFoodie from "../../images/gallery/OGFoodie.webp";
import OGCreative from "../../images/gallery/OGCreative.webp";
import OGNinja from "../../images/gallery/OGNinja.webp";
import OGUndead from "../../images/gallery/OGUndead.webp";
import OGOni from "../../images/gallery/OGOni.webp";
import OGSeacreature from "../../images/gallery/OGSeacreature.webp";
import OGDeity from "../../images/gallery/OGDeity.webp";
import { ResidentsItem, ResidentImage, ResidentsSectionInfo, ResidentInfoTitle } from "../../tailwind-css";

export function ResidentsSection() {
    return (
        <SectionContainter>
            <div className="flex flex-col items-center bg-antiquewhite-100 rounded-3xl">
            <TwoColumnWrapper>
                {/* <div className="grid grid-cols-3">
                    <img src={ER1} className="rounded-full gap-3" />
                    <img src={ER5} className="rounded-full gap-3" />
                    <img src={ER4} className="rounded-full gap-3" />
                </div> */}
                <Title>THE BEGINNING...</Title>
                <p className="text-xl text-center">Kumo's World is full of mystery and adventure. During the expedition phase, Kumo found some explorers laying under an ancient cherry blossom tree and invited them on an adventure to journey through the unknown world. They were all a little puzzled by how they arrived there to begin with, but Kumo's smile and bright energy gave them enough reassurance that everything was going to be ok.</p>
                <p className="text-xl text-center"> They accepted the challenge, despite not knowing each other or what lay ahead. Their loving and open hearts allowed them to all become friends in a short space of time and with all that, through all their ups and downs, the challenges they faced together, they managed to meet with 9 different classes of residents and made many new friends along the way. </p>
                <p  className="text-xl text-center">The explorers now live in the world with Kumo as new residents and together they go on adventures to discover the world together, where anything is possible. A place where one can expect the unexpected.</p>
                <hr />
                <Title>MEET THE RESIDENTS</Title>
                <p className="text-xl">There are currently 9 known classes of residents, each residing in their own unique part of the world. They each have their own characteristic traits as well as recognisable personality types.</p>
                <div className="grid md:grid-cols-3 grid-cols-2 gap-8">
                    <ResidentsItem>
                        <ResidentImage src={OGApe} />
                        <div>

                            <ResidentsSectionInfo>
                                <ResidentInfoTitle>APES</ResidentInfoTitle>
                                <p className="text-base">
                                    Loyal
                                <br />Family-oriented
                                <br />Playful</p>
                            </ResidentsSectionInfo>
                        </div>

                    </ResidentsItem>
                    <ResidentsItem>
                        <ResidentImage src={OGHarajuku} />
                        <div >

                            <ResidentsSectionInfo>
                                <ResidentInfoTitle>HARAJUKUS</ResidentInfoTitle>
                                <p className="text-base">Colorful
                                <br />Fun
                                <br />Care-free</p>
                            </ResidentsSectionInfo>
                        </div>
                    </ResidentsItem>
                    <ResidentsItem>
                        <ResidentImage src={OGFoodie} />
                        <div>

                            <ResidentsSectionInfo>
                                <ResidentInfoTitle>FOODIES</ResidentInfoTitle>
                                <p className="text-base">
                                Experimental
                                <br /> Sharing
                                <br /> Passionate</p>
                            </ResidentsSectionInfo>
                        </div>
                    </ResidentsItem>
                    <ResidentsItem>
                        <ResidentImage src={OGCreative} />
                        <div>
                            <ResidentsSectionInfo>
                                <ResidentInfoTitle>CREATIVES</ResidentInfoTitle>
                                <p className="text-base">
                                Innovative
                                <br /> Ambitious
                                <br /> Artistic</p>
                            </ResidentsSectionInfo>
                        </div>
                    </ResidentsItem>
                    <ResidentsItem>
                        <ResidentImage src={OGNinja} />
                        <div>

                            <ResidentsSectionInfo>
                                <ResidentInfoTitle>NINJAS</ResidentInfoTitle>
                                <p className="text-base">
                                Traditional
                                <br /> Proud
                                <br /> Determined</p>
                            </ResidentsSectionInfo>
                        </div>
                    </ResidentsItem>
                    <ResidentsItem>
                        <ResidentImage src={OGSeacreature} />
                        <div>

                            <ResidentsSectionInfo>
                                <ResidentInfoTitle>SEA CREATURES</ResidentInfoTitle>
                                <p className="text-base">
                                Adventurous
                                <br /> Out-spoken
                                <br /> Fierce</p>
                            </ResidentsSectionInfo>
                        </div>
                    </ResidentsItem>
                    <ResidentsItem>
                        <ResidentImage src={OGUndead} />
                        <div>

                            <ResidentsSectionInfo>
                                <ResidentInfoTitle>UNDEAD</ResidentInfoTitle>
                                <p className="text-base">
                                Curious
                                <br /> Peaceful
                                <br /> Introverted</p>
                            </ResidentsSectionInfo>
                        </div>
                    </ResidentsItem>
                    <ResidentsItem>
                        <ResidentImage src={OGOni} />
                        <div>

                            <ResidentsSectionInfo>
                                <ResidentInfoTitle>ONIS</ResidentInfoTitle>
                                <p className="text-base">
                                Protective
                                <br /> Guiding
                                <br /> Empathetic</p>
                            </ResidentsSectionInfo>
                        </div>
                    </ResidentsItem>
                    <ResidentsItem>
                        <ResidentImage src={OGDeity} />
                        <div>

                            <ResidentsSectionInfo>
                                <ResidentInfoTitle>DEITIES</ResidentInfoTitle>
                                <p className="text-base">
                                Mysterious
                                <br /> Harmonious
                                <br /> Serene
                                </p>
                            </ResidentsSectionInfo>
                        </div>
                    </ResidentsItem>
                </div>
            </TwoColumnWrapper>
            </div>
        </SectionContainter>
    );
}