import React, { useState } from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { NavBar } from "../../components/NavBar";
import BackgroundImage from "../../images/bgcontactus.png";
import { Link as RouterLink } from "react-router-dom";
import { FooterIconSection } from "../../components/FooterIcons";

const encode = (data: any) => {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&");
}

const ContactFormBackground = styled.div`
  ${tw`
      background-color[#dff5f4]
      text-base
      shadow-md 
      rounded 
      px-8 
      pt-6
      pb-6
      w-full
  `}
`
const SubmitButton = tw.input`
    hover:cursor-pointer
    text-white 
    background-color[#61b0ac] 
    font-bold 
    uppercase 
    text-sm 
    px-6 
    py-3 
    rounded 
    shadow 
    hover:shadow-lg 
    outline-none 
    focus:outline-none 
    mr-1 
    mb-1
`

const ContactUsContainer = styled.div`
    ${tw`
        w-full
        flex
        flex-col
        // h-screen
        relative
        h-full
        align-items[center]
    `}
`;

const LandingSection = styled.div`
  ${tw`
        w-full
        overflow-scroll
        flex
        flex-col
        min-h-screen
    `};
  background-image: url(${BackgroundImage});
  background-size: cover;
  background-blend-mode: overlay;
`;

const FormContainer = styled.div`
${tw` 
    width[100%]
    height[70%] 
    my-6 
    mr-auto
    xl:ml-20
    md:ml-14
    max-w-3xl
`}`

const SuccessAlertBox = styled.div`
    ${tw`
    transition 
    ease-in-out 
    delay-150
    duration-300
    background-color[#71bdee] 
    border-t 
    border-b 
    border-color[#71bdee] 
    text-white
    px-4 py-3
    `}
`

const ErrorAlertBox = styled.div`
    ${tw`
    background-color[#eb5173] 
    border-t 
    border-b 
    border-color[#eb5173] 
    text-white
    px-4 py-3
    `}
`

function SuccessAlertBoxSection() {
    return (<SuccessAlertBox role="alert">
        <p className="font-bold">Message successfully sent</p>
        <p className="text-sm">Thank you for getting in touch. We will get back to you within 48 hours. Thank you for your patience.</p>
    </SuccessAlertBox>)
};

function ErrorAlertBoxSection() {
    return (<ErrorAlertBox role="alert">
        <p className="font-bold">Message failed to send</p>
        <p className="text-sm">Something unexpected happened and we cannot process your form at this moment. Sorry for any inconvenience. Please feel free to reach out to us on our social media platforms.</p>
    </ErrorAlertBox>)
};

export function ContactUs() {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [message, setMessage] = useState('');
    const [website, setWebsite] = useState('')
    const [submissionSuccess, setSubSuccess] = useState(false)
    const [submissionFail, setSubFail] = useState(false)

    const handleSubmit = (e: any) => {
        let formData = {
            "first-name": firstName,
            "last-name": lastName,
            "company-name": companyName,
            "email": email,
            "message": message,
        }

        try {
            fetch("/", {
                method: "POST",
                headers: { "Content-Type": "application/x-www-form-urlencoded" },
                body: encode({ "form-name": "contact", ...formData })
            }).then(() => {
                setSubSuccess(submissionSuccess => !submissionSuccess)
                setTimeout(() => {
                    setSubSuccess(submissionSuccess => !submissionSuccess);
                }, 5000);
            })
        }
        catch (Error) {
            setSubFail(submissionFail => !submissionFail)
        };
        e.preventDefault(); // 👈️ prevent page refresh

        // 👇️ access input values here
        console.log('lastName 👉️', lastName);

        // 👇️ clear all input values in the form
        setFirstName("");
        setLastName("");
        setCompanyName("")
        setEmail("")
        setMessage("")
        setWebsite("")
    };

    return (
        <ContactUsContainer>
            <LandingSection>
                <NavBar />
                <FormContainer>
                    <div className="mb-2 mt-2 border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        <div className="flex text-center justify-between p-5 border-b border-solid border-gray-300 rounded-t flex-col">
                            <h3 className="text-3xl font=semibold mb-3">Get in Touch</h3>
                            <p className="text-lg">If you are interested in collaborating with Kumo x World, </p>
                            <p className="text-lg">email us at kumoxworld@gmail.com with subject title "COLLABORATION ENQUIRY".</p>
                            <br></br>
                            <p className="text-lg">Look forward to hearing from you.</p>
                            

                        </div>
                        <div className="relative p-6 flex-auto">
                        </div>
                    </div>
                </FormContainer>
            </LandingSection>
            <FooterIconSection></FooterIconSection>
        </ContactUsContainer>)
};
