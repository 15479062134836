import React from "react";
import styled from "styled-components";
import tw from 'twin.macro'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import ER1 from "../../images/gallery/ER1.png";
import ER2 from "../../images/gallery/ER2.png";
import ER3 from "../../images/gallery/ER3.png";
import ER4 from "../../images/gallery/ER4.png";
import ER5 from "../../images/gallery/ER5.png";
import ER6 from "../../images/gallery/ER6.png";
import ER7 from "../../images/gallery/ER7.png";
import ER8 from "../../images/gallery/ER8.png";
import ER10 from "../../images/gallery/ER10.png";
import ER11 from "../../images/gallery/ER11.png";
import ER12 from "../../images/gallery/ER12.png";
import ER13 from "../../images/gallery/ER13.png";
import ER14 from "../../images/gallery/ER14.png";
import ER15 from "../../images/gallery/ER15.png";
import ER16 from "../../images/gallery/ER16.png";
import ER17 from "../../images/gallery/ER17.png";
import ER18 from "../../images/gallery/ER18.png";
import ER19 from "../../images/gallery/ER19.png";
import ER20 from "../../images/gallery/ER20.jpg";
import ER21 from "../../images/gallery/ER21.png";
import { SectionContainter, Title } from "../../containers/WhoAreWe";
import { ColumnWrapper, ExternalLinks, } from "../../tailwind-css";

export function ExpeditionResidentsSection() {
    return (
        <SectionContainter>
            <ColumnWrapper>
                <div className="flex flex-col items-center bg-antiquewhite-100 rounded-3xl pl-10 pr-10">
                    <ColumnWrapper>
                        <Title>CURRENT ARC - THE MOTHERTREE SEASON 1 (completed)</Title>
                        <p className="text-xl">Season 2 to be continued.</p>
                        {/* <ExternalLinks href="https://kumoxworld.medium.com">You can read the first season of Mother Tree Arc on our medium page here.</ExternalLinks> */}

                        <p className="text-xl text-center">Here are some memorable characters from The Mother Tree Arc Season 1</p>

                        <div className="grid sm:grid-cols-6 grid-cols-3 gap-5">
                            <img src={ER1} className="rounded-full" />
                            <img src={ER5} className="rounded-full" />
                            <img src={ER2} className="rounded-full" />
                            <img src={ER3} className="rounded-full" />
                            <img src={ER6} className="rounded-full" />
                            <img src={ER7} className="rounded-full" />
                        </div>
                    </ColumnWrapper>
                </div>
                <div className="flex flex-col items-center bg-antiquewhite-100 rounded-3xl pl-10 pr-10">
                    <ColumnWrapper>
                        <Title>PREVIOUS ARCS</Title>
                        <p className="text-xl text-left">Our previous stories included Chinese New Year, The Lost Residents, Snowy Mountain, The First Adventures.</p>

                        <div className="grid sm:grid-cols-6 grid-cols-3 gap-5">
                            <img src={ER20} className="rounded-full" />
                            <img src={ER10} className="rounded-full" />
                            <img src={ER11} className="rounded-full" />
                            <img src={ER12} className="rounded-full" />
                            <img src={ER13} className="rounded-full" />
                            <img src={ER14} className="rounded-full" />
                            <img src={ER15} className="rounded-full" />
                            <img src={ER16} className="rounded-full" />
                            <img src={ER17} className="rounded-full" />
                            <img src={ER18} className="rounded-full" />
                            <img src={ER19} className="rounded-full" />
                            <img src={ER21} className="rounded-full" />
                        </div>
                    </ColumnWrapper>
                </div>
                {/* <Item>
                    <ERImage src={ER1} />
                    <div>
                        <Title>OUR BRAND</Title>
                        <ResidentsSectionInfo>
                            Kumo's World is one of the original cute brands, founded at the end 2021. A brand that is loved by many within the Web3 space, Kumo's World is about making a long-lasting and positive impact in people’s lives through imaginative story-telling and quality experiences that anyone can get lost in.
                        </ResidentsSectionInfo>
                    </div>

                </Item> */}
                {/* <Item>
                    <div className="order-2 sm:order-1" >
                        <Title>WHO ARE WE?</Title>
                        <SectionInfo>
                            An ambitious group of people who believes that through creative storytelling and experiences we can leave a postitive and longlasting impact.
                        </SectionInfo>
                    </div> <ERImage className="order-1 sm:order-2" src={ER2} />
                </Item>
                <Item>
                    <ERImage src={ER3} />
                    <div>
                        <Title>OUR MISSION</Title>
                        <SectionInfo>
                            “At the end of the day, the mission behind the project is rather simple: to help people realize that happiness is found within themselves.
                            We wish to inspire people to be the change they wish to see, and in doing so change the world for the better.”
                            - Kumo
                        </SectionInfo>
                    </div>
                </Item> */}
                {/* <Item>
                    <div className="order-2 sm:order-1" ><br></br>
                        <Title className="">WHO ARE WE?</Title>
                        <ResidentsSectionInfo>
                            An ambitious group of people who believes that through creative storytelling and experiences we can leave a postitive and longlasting impact.
                        </ResidentsSectionInfo>
                    </div> <ERImage className="order-1 sm:order-2" src={ER4} />
                </Item>
                <Item>
                    <ERImage src={ER5} />
                    <div>
                        <Title>OUR MISSION</Title>
                        <ResidentsSectionInfo>
                            “At the end of the day, the mission behind the project is rather simple: to help people realize that happiness is found within themselves.
                            We wish to inspire people to be the change they wish to see, and in doing so change the world for the better.”
                            - Kumo
                        </ResidentsSectionInfo>
                    </div>
                </Item> */}
            </ColumnWrapper>
        </SectionContainter >
    );
}