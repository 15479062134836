import styled from "styled-components";
import tw from "twin.macro";
import { NavBar } from "../../components/NavBar";
import BackgroundImage from "../../images/bg2.png";
import { FooterIconSection } from "../../components/FooterIcons";
import { GalleryImage } from "../../containers/Footer";
import TeamPhoto from "../../images/teamphoto.png";
import { Item, SectionContainter, SectionInfo, Title, TwoColumnWrapper, WhoAreWeSection } from "../../containers/WhoAreWe";
import { TeamMembers } from "../../containers/TeamMembers";
import { NFTInfoSection } from "../../containers/NFTInfo";

import EtherealBanner from "../../images/gallery/ethereal-collage.png";

const AboutUsContainer = styled.div`
    ${tw`
        w-full
        flex
        flex-col
        h-full
        relative
        align-items[center]
    `}
`;

const NavBG = tw.div`
    w-full
    background-color[#87bbb8]
`

const LandingSection = styled.div`
  ${tw`
        w-full
        // h-screen
        overflow-scroll
        flex
        flex-col
    `};
//   background-image: url(${BackgroundImage});
//   background-size: cover;
//   background-blend-mode: overlay;
`;

const ImageBanner = styled.div`
    ${tw`
        grid 
        max-w-full
        lg:grid 
    `}
`

export function AboutUs() {
    return (
        <AboutUsContainer>
            <NavBG><NavBar /></NavBG>
            <WhoAreWeSection></WhoAreWeSection>
            <img src={EtherealBanner} />
            {/* <NFTInfoSection></NFTInfoSection> */}
            {/* <GalleryImage src={TeamPhoto}></GalleryImage> */}
            {/* <TeamMembers></TeamMembers> */}
            <FooterIconSection></FooterIconSection>
        </AboutUsContainer>)
};
