
import "react-responsive-carousel/lib/styles/carousel.min.css";
import StoryReading from "../../images/storyreading.png";
import { Item, SectionContainter, SectionInfo, Title, TwoColumnWrapper } from "../WhoAreWe";

export function GeneralInfoSection() {
    return (
        <SectionContainter>
            <TwoColumnWrapper className="pb-5">
                <Item>
                    <img src={StoryReading} />
                    <div><Title>KUMO X WORLD</Title>
                        <SectionInfo>
                            Join us in fostering the inner joys that we have each had as children. To draw, create, face challenges, and be outwardly passionate simply because we want to. To Realise that our only inhibition from self-expression is ourselves.
                        </SectionInfo>
                    </div>
                </Item>
                <Item>
                    <SectionInfo>
                        The word KUMO originates from 雲 (cloud) in Japanese. A cloud has no boundaries and is forever expanding, which represents the infinite possibilities in Kumo's World, where anything is possibile as long as you can imagine it.
                    </SectionInfo>
                </Item>
            </TwoColumnWrapper>
        </SectionContainter>
    );
}