import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { NavBar } from "../../components/NavBar";
import BackgroundImage from "../../images/bg5.png";
import { BsArrowDownCircle } from "react-icons/bs";
import { Link } from "react-scroll";

const TopSectionContainer = styled.div`
    ${tw`
        w-full
        flex
        flex-col
        h-screen
        relative
    `}
`;

export const LandingSection = styled.div`
  ${tw`
        w-full
        h-screen
        flex
        flex-col
    `};
  background-image: url(${BackgroundImage});
  background-size: cover;
  background-position: bottom 10% left;
  background-blend-mode: overlay;
`;


const InfoSection = styled.div`
  ${tw`
        absolute
        top[90px]
        left-14
        lg:top[115px]
        lg:right-20
        lg:left-auto
        2xl:right-48
        2xl:top[240px]
        2xl:left-auto
    `};
`;

const FloatingText = styled.h1`
  ${tw`
        m-0
        font-black
        text-white
        font-size[35px]
        line-height[35px]
        md:font-size[40px]
        md:line-height[45px]
        lg:font-size[65px]
        lg:line-height[55px]
        2xl:font-size[100px]
        2xl:line-height[100px]
        font-family["Archivo Narrow"]
        flex
        items-center
    `};
`;

const FloatingTextSmall = styled.div`
  ${tw`
        m-0
        font-black
        text-white
        font-size[20px]
        line-height[35px]
        md:font-size[30px]
        md:line-height[45px]
        lg:font-size[45px]
        lg:line-height[65px]
        2xl:font-size[55px]
        2xl:line-height[100px]
        font-family["Archivo Narrow"]
        flex
        items-center
    `};
`;

const DescriptionText = styled.p`
  ${tw`
        text-xl
        lg:font-size[2rem]
        2xl:font-size[45px]
        line-height[35px]
        text-white
        max-w-xs
        lg:line-height[65px]
        2xl:line-height[100px]
        lg:max-w-lg
        2xl:max-w-xl
    `};
`;

const NavBG = tw.div`
    background-color[#36375b]
    opacity-80
`

export function TopSection() {
    return <TopSectionContainer>
        <LandingSection>
            <NavBar/>
            <InfoSection>
                <FloatingText>RECONNECT</FloatingText>
                <FloatingTextSmall>
                    WITH YOUR</FloatingTextSmall>
                <FloatingText>
                    INNER CHILD</FloatingText>
                <DescriptionText>
                    Explore. Create. Imagine. Believe.
                </DescriptionText>
            </InfoSection>
        </LandingSection>
    </TopSectionContainer>
};