import React from "react";
import styled from "styled-components";
import tw from 'twin.macro'
import { Element } from 'react-scroll'
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import WhoAreWeImg from "../../images/whoarewe.png"
import MissionImg from "../../images/selflove.png"
import StoryReading from "../../images/storyreading.png";
import KumoFrenz from "../../images/kumofrenz.png";
import { BsArrowDownCircle } from "react-icons/bs";
import { Link } from "react-scroll";

export const SectionContainter = tw.div`
    sm:w-full
    md:w-4/5
    lg:w-4/5
    xl:w-4/5
    // lg:h-screen
    // md:h-screen
    // xl:h-screen
    // 2xl:h-screen
    // height[70vh]
    // h-screen
    flex
    relative
    justify-center
`;

export const Title = tw.h1`
    text-2xl
    xl:text-4xl
    2xl:text-4xl

    text-gray-800
    font-bold
    text-center
`;

export const SectionInfo = tw.p`
    pl-5
    pr-5
    // lg:font-size[1.3rem]
    text-xl
    2xl:text-3xl
    text-center
    mt-2
    2xl:mt-2
    w-full
    xl:max-w-3xl
`;

export const TwoColumnWrapper = tw.div`
    padding-top[5%]
    padding-bottom[8%]
    flex
    flex-col
    justify-center
    items-center
    w-10/12
    h-full
    // 2xl:mt-5
    align-items[center]
    space-y-5
`;

export const Item = styled.div`
  ${tw`
        flex
        // space-y-3
        sm:flex-direction[row]
        flex-direction[column]
        align-items[center]
    `}
  img {
    ${tw`
            md:w-4/12
            w-10/12
        `}
  }
`;

export function WhoAreWeSection() {
    return (
        <SectionContainter>
            <TwoColumnWrapper>
                <Item>
                    <img className="lg:w-auto w-full" src={WhoAreWeImg} />
                    <div>
                        <Title>OUR BRAND</Title>
                        <SectionInfo>
                        Kumo x World is built on the power of storytelling and creating positive experiences.
                        </SectionInfo>
                    </div>
                    <div>
                        <Title>OUR MISSION</Title>
                        <SectionInfo>
                        Kumo x World is to spread kindness and love, one person at a time.
                        </SectionInfo>
                    </div>

                </Item>
                <Item>
                    <div className="order-2 sm:order-1" >
                        <Title>OUR BELIEF</Title>
                        <SectionInfo>
                            We believe in the ripple effect of positivity - a smile or act of love can create a chain reaction of good deeds
                        </SectionInfo>
                        </div><div>
                        <Title>OUR GOAL</Title>
                        <SectionInfo>
                        Simply to make the world, that we live in, a kinder and more loving place.
                        </SectionInfo>
                    </div> <img className="order-1 sm:order-2" src={KumoFrenz} />
                </Item>
                <Item>
                    <img src={MissionImg} />
                    <div>
                        <Title>A MESSAGE FROM OUR FOUNDER</Title>
                        <SectionInfo>
                            “We all have a story to tell, and at our core, we just want to be kind and loving. That's why we want to help people tap back into their playful side, the one that remembers how much fun it is to be themselves. When people rediscover that joy, it makes them want to be kind to others. It's like a wave of happiness that keeps spreading, making the world a little brighter, one person at a time.”
                            - Kumo
                        </SectionInfo>
                    </div>
                </Item>
            </TwoColumnWrapper>
        </SectionContainter>
    );
}