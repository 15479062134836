import styled from "styled-components";
import tw from "twin.macro";
import { Logo } from "../Logo";
import { deviceSize } from "../responsive";
import { useMediaQuery } from "react-responsive";
import { slide as Menu } from "react-burger-menu";
import styles from "./menuStyles"
import { Link as RouterLink, NavLink } from "react-router-dom"
import { useState } from "react";
import { DropdownHome } from "../DropdownMenus";
import { Link } from "react-scroll";

export const NavContainer = styled.div`
width: 100%;
    ${tw`
        flex
        h-20
        pl-10
        pr-6
        pt-4
        border-b-2
        border-gray-200
        border-opacity-20
        items-center
        self-center
    `}
`;

const NavItems = tw.ul`
    list-none
    w-full
    h-auto
    lg:w-auto
    lg:h-full
    flex
    lg:ml-20
    md:ml-10
    sm:ml-5
    justify-center
    align-items[center]
`;

const NavItem = tw.li`
    lg:mr-5
    xl:mr-5
    2xl:mr-8
    md:mr-5
    sm:mr-3
    flex
    items-center
    justify-center
    min-h-full
    text-white
    cursor-pointer
    // md:text-xl
    font-size[2.5vmin]
    sm: text-2xl
    sm:line-height[3rem]
    transition-colors
    transition-duration[300ms]
    hover:text-yellow-400
    box-content
    mb-2
    lg:mb-0
`;


export function NavBar() {

    let activeClassName = "underline";

    const [isOpen, setOpen] = useState(false)

    const handleIsOpen = () => {
        setOpen(!isOpen)
    }

    const closeSideBar = () => {
        setOpen(false)
    }
    const isMobile = useMediaQuery({ maxWidth: deviceSize.mobile })

    const navItems = <NavItems>
        <NavItem>
            <NavLink to="/" end className={({ isActive }) =>
                isActive ? activeClassName : undefined
            } onClick={closeSideBar}>Home</NavLink>
        </NavItem>
        <NavItem>
            <NavLink to="/storytime" className={({ isActive }) =>
                isActive ? activeClassName : undefined
            }>Storytime</NavLink>
        </NavItem>
        <NavItem>
            <NavLink to="/about" className={({ isActive }) =>
                isActive ? activeClassName : undefined
            } onClick={closeSideBar}>About</NavLink>
        </NavItem>
        <NavItem>
            <NavLink to="/contact" className={({ isActive }) =>
                isActive ? activeClassName : undefined
            }>Contact</NavLink>
        </NavItem>
        {/* <NavItem><a href="http://shop.kumoxworld.com/" target="_blank" onClick={closeSideBar}>Shop</a></NavItem> */}
    </NavItems>;

    return <NavContainer>
        <Logo />
        {isMobile && <Menu
            right styles={styles} isOpen={isOpen}
            onOpen={handleIsOpen}
            onClose={handleIsOpen}
        >
            {navItems}
        </Menu>}
        {!isMobile && navItems}
    </NavContainer>

};