import styled from "styled-components";
import tw from "twin.macro";

export const ColumnWrapper = styled.div`
    ${tw`
        padding-top[5%]
        padding-bottom[5%]
        flex
        flex-col
        justify-center
        items-center
        h-full
        align-items[center]
        space-y-5
    `}
`

export const ExpeditionResidentImage = styled.img`
    ${tw`
        md:width[25%]
        width[50%]
        rounded-full
    `}
`
export const ExternalLinks = tw.a`
    color[#ff478b]
    text-2xl
    text-center
    cursor-pointer
`

export const ResidentImage = styled.img`
    ${tw`
        sm:width[50%]
        width[85%]
        rounded-3xl
        object-cover
        // object-contain
    `}
`
export const ResidentsItem = styled.div`
  ${tw`
        flex
        // space-y-4
        sm:flex-direction[row]
        flex-direction[column]
        // align-items[center]
        // line-height[25px]
    `}
`;

export const ResidentInfoTitle = tw.p`
    // font-bold
    lg:text-2xl
    font-size[1.3rem]
    underline
    
`

export const ResidentsSectionInfo = styled.div`
    ${tw`
        sm:pl-5
        sm:pr-5
        pl-2
        pr-2
        text-justify
        mt-2
        2xl:mt-2
        w-full
        xl:max-w-3xl
    `}
`;

export const CustomButton = styled.button`
    ${tw`
        opacity-90
        line-height[0%]
        background-color[#4e9d7f]
        hover:background-color[#00517e]
        text-white 
        text-lg 
        p-2
        pl-4
        pr-4
        width[fit-content]
        rounded-full
    `}
`