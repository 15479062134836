import React from "react";
import styled from "styled-components";
import tw from 'twin.macro'
import { Element } from 'react-scroll'
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import WhoAreWeImg from "../../images/whoarewe.png"
import Run from "../../images/run.gif"
import { BsArrowDownCircle } from "react-icons/bs";
import { Link } from "react-scroll";
import BackgroundImage from "../../images/bg3.png";
import { FooterIconSection } from "../../components/FooterIcons";

import GalleryImage1 from "../../images/gallery/1.webp";
import GalleryImage2 from "../../images/gallery/2.webp";
import GalleryImage3 from "../../images/gallery/3.png";
import GalleryImage4 from "../../images/gallery/4.webp";
import GalleryImage5 from "../../images/gallery/5.png";
import GalleryImage6 from "../../images/gallery/6.png";
import GalleryImage7 from "../../images/gallery/7.webp";
import GalleryImage8 from "../../images/gallery/8.png";
import GalleryImage9 from "../../images/gallery/9.webp";
import GalleryImage10 from "../../images/gallery/10.webp";
import GalleryImage11 from "../../images/gallery/11.webp";
import GalleryImage12 from "../../images/gallery/12.webp";
import GalleryImage14 from "../../images/gallery/14.webp";
import GalleryImage15 from "../../images/gallery/15.webp";
import GalleryImage16 from "../../images/gallery/16.webp";
import GalleryImage17 from "../../images/gallery/17.png";
import GalleryImage20 from "../../images/gallery/20.png";
import GalleryImage21 from "../../images/gallery/21.webp";



const FooterSectionContainter = tw.div`
    w-full
    // h-screen
    flex
    flex-col
    relative
`;

const LandingSection = styled.div`
  ${tw`
        w-full
        flex
        flex-col
    `};
  background-color: #4cc4b7;
  background-size: cover;
  background-blend-mode: overlay;
`;

export const Gallery = styled.div`
    ${tw`
        // position[absolute]
        grid 
        grid-cols-4
        max-w-full
        lg:grid 
        lg:grid-cols-6
    `}
`

export const GalleryImage = styled.img`
    ${tw`
        w-full
    `}
`

export function FooterSection() {
    return <FooterSectionContainter>
        <Gallery>
            <GalleryImage src={GalleryImage1}></GalleryImage>
            <GalleryImage src={GalleryImage2}></GalleryImage>
            <GalleryImage src={GalleryImage11}></GalleryImage>
            <GalleryImage src={GalleryImage4}></GalleryImage>
            <GalleryImage src={GalleryImage5}></GalleryImage>
            <GalleryImage src={GalleryImage7}></GalleryImage>

            <GalleryImage src={GalleryImage14}></GalleryImage>
            <GalleryImage src={GalleryImage3}></GalleryImage>
            <GalleryImage src={GalleryImage9}></GalleryImage>
            <GalleryImage src={GalleryImage8}></GalleryImage>
            <GalleryImage src={GalleryImage6}></GalleryImage>
            <GalleryImage src={GalleryImage12}></GalleryImage>

            {/* <GalleryImage src={GalleryImage10}></GalleryImage>
            <GalleryImage src={GalleryImage21}></GalleryImage>
            <GalleryImage src={GalleryImage15}></GalleryImage>
            <GalleryImage src={GalleryImage16}></GalleryImage>
            <GalleryImage src={GalleryImage17}></GalleryImage>
            <GalleryImage src={GalleryImage20}></GalleryImage> */}
        </Gallery>
            <div><FooterIconSection></FooterIconSection></div>
    </FooterSectionContainter>
};